<!--  -->
<template>
  <div class="landingpage">
    <div class="mainheader">
      <!-- 头部 -->
      <div class="header">
        <div class="hupleft" @click="goBack">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-back"></use>
          </svg>
          Back to all forms
        </div>
        <div class="hupright">
          <el-select v-model="aselect" placeholder="Actions">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button>Export</el-button>
          <el-button>Edit</el-button>
        </div>
      </div>
      <div class="intro">
        <span>{{ landingpageid }}</span>
      </div>
      <div class="conmain">
        <div class="conl" @click="gotox">
          <svg class="icon small_img" aria-hidden="true">
            <use href="#icon-small"></use>
          </svg>
          <p class="viewform">
            landing page url
            <svg class="icon" aria-hidden="true">
              <use href="#icon-sharewhite"></use>
            </svg>
          </p>
        </div>
        <div class="conr">
          <p>Publish date</p>
          <h3>{{ Createddate }}</h3>
          <p>URL</p>
          <span>{{ lastsub }}</span>
        </div>
      </div>
      <!-- tab菜单 -->
      <div class="dtabs">
        <el-menu
          :default-active="activeName"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="Performance">Performance</el-menu-item>
        </el-menu>
      </div>
    </div>
    <Performance v-if="Performanceshow"> </Performance>
  </div>
</template>

<script>
import Performance from '@/views/landingPage/detail/components/Performance.vue'
export default {
  components: {
    Performance,
  },
  data() {
    return {
      aselect: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      lastsub: "https://info.cloudcc.com/all-in-oneqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",
      Createddate: "February 3,2021",
      activeName: "Performance",
      landingpageid: "",//传进来的id
      Performanceshow: true,//Performance 模块显示隐藏
    }
  },
  created() {
    if (this.$route.params.id) {
      this.landingpageid = this.$route.params.id;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotox() {
      this.$router.push({
        path: this.lastsub,
      });
    },
    // tab菜单切换
    handleSelect(key) {
      if (key == 'Performance') {
        this.Performanceshow = true;
      }
    }
  }
}
</script>

<style lang='scss' scoped >
.icon {
  display: inline-block;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #409eff;
  font-weight: bold;
  font-family: Helvetica;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
}

::v-deep .el-input__icon {
  line-height: 0;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 103px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #006dcc;
  letter-spacing: 0;
  text-align: center;
}
.landingpage {
  width: 98.5%;
  margin-left: 0.7%;
  .mainheader {
    width: 100%;
    margin-top: 9px;
    padding-top: 21px;
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 3px;
    .header {
      height: 441px;
      //   background-color: #fff;
      width: 98.5%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      .hupleft {
        height: 411px - 20px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #006dcc;
        letter-spacing: 0;
        cursor: pointer;
        img {
          height: 15.6px;
          width: 15.6px;
          margin-left: 13.4px;
        }
      }
      .hupright {
        height: 50px;
        width: 300px;
        margin-right: -55px;
        button {
          height: 30px;
          border: 1px solid #dedcda;
          border-radius: 3px;
          padding-top: 8px;
        }

        button:nth-child(2) {
          width: 77px;
          margin-left: 10px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #006dcc;
          letter-spacing: 0;
          text-align: center;
        }
        button:nth-child(3) {
          width: 57px;
          background: #006dcc;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }
    .intro {
      margin-bottom: 9px;
      margin-left: 10px;
      span {
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bold;
      }
    }
    .conmain {
      display: flex;
      .conl {
        margin-left: 10px;
        width: 289px;
        height: 244px;
        background: #f0f0f0;
        border: 1px solid #dedcda;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        .icon {
          width: 280px;
          height: 235px;
          margin-top: 4.5px;
          margin-left: 4.5px;
        }
        .viewform {
          position: absolute;
          top: 100px;
          left: 30%;
          display: none;
          font-size: 14px;
          color: #fff;
          .icon {
            width: 14px;
            height: 14px;
          }
        }
      }

      .conl:hover {
        transition: background-color 0.3s;
        background-color: rgba(81, 111, 144, 0.85);
      }
      .conl:hover .small_img {
        opacity: 0.05;
      }
      .conl:hover .viewform {
        display: block;
      }
      .conr {
        margin-left: 36px;
        p {
          font-family: PingFangSC-Semibold;
          font-size: 12px;
          color: #888888;
          letter-spacing: 0;
          text-align: center;
          margin-bottom: 5px;
          text-align: left;
        }
        h3 {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          text-align: left;
          margin-bottom: 23px;
          font-weight: bold;
        }
        span {
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #006dcc;
          letter-spacing: 0;
          text-align: center;
          display: inline-block;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
        }
      }
    }
    .dtabs {
      width: 100%;
      margin-top: 40px;
    }
  }
}
</style>
