<!-- Performance -->
<template>
  <div class="Performance">
    <div class="perdate">
      <div class="Daterange">
        Date range：
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span>{{ Daterange }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(range, num) in Dateranges"
              :key="num"
              :command="range"
              >{{ range }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="datepicker" v-show="Daterange == 'Custom time period'">
        <el-date-picker
          v-model="datepickervalue"
          type="daterange"
          range-separator="-"
        >
        </el-date-picker>
      </div>
      <div class="Daterange">
        Frequency：
        <el-dropdown @command="handledate">
          <span class="el-dropdown-link">
            <span>{{ Frequency }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(range, num) in Frequencies"
              :key="num"
              :command="range"
              >{{ range }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Analyzedate :analyzedate="analyze"></Analyzedate>
    <div class="pageview">
      <div class="pagechart">
        <el-dropdown @command="handlepage">
          <span class="el-dropdown-link">
            <span>{{ page }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(range, num) in pages"
              :key="num"
              :command="range"
              >{{ range }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <Analyzecharts
        :echartStyle="style"
        :titleText="title"
        :opinion="barName"
        :opinionData="info"
        :x="barX"
        :multipleSelection="multipleSelection"
        @legendselect="legendselect"
      >
      </Analyzecharts>
    </div>
    <div class="source">
      <el-table
        :data="tableData"
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
        border
        :cell-style="{ padding: '0' }"
        :row-style="{ height: '64px' }"
        :header-cell-style="{ background: '#FAFAF9', height: '40px' }"
        @selection-change="handleSelectionChange"
        min-height="300px"
        ref="multipleTable"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <template v-for="(tit, titindex) in tableAttr">
          <slot :item="titindex">
            <el-table-column>
              <template slot="header">
                <div class="tableHeadField">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="tit.column"
                  >
                    <span slot="reference">
                      {{ tit.column }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    :style="{
                      height: '12px',
                      'vertical-align': '',
                      transform: tit.sort ? 'rotate(180deg)' : '',
                    }"
                    v-show="tit.lock"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    width="100"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    :ref="`${tit.column}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox>{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <span slot="reference" @click="titimg(tit)">
                      <svg class="icon" aria-hidden="true" style="width: 11px">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <span :style="`color:${scope.row.color}`">{{
                    scope.row[tit.key]
                  }}</span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </template>
        <template slot="empty">
          <div class="isnot-box">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-empty-search"></use>
            </svg>
            <div class="not-title">No list cleanup needed.</div>
            <div class="not-text">
              You don`t have any unused lists right now.
            </div>
          </div>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import Analyzedate from '@/views/marketForm/components/Analyzedate.vue'
import Analyzecharts from '@/views/marketForm/components/Analyzeecharts'
export default {
  components: {
    Analyzedate,
    Analyzecharts,
  },
  data() {
    return {
      Daterange: 'Last 30 days',
      Dateranges: [
        'Today',
        'This week',
        'This year',
        'Last 30 days',
        'All time',
        'Custom time period',
      ],
      Frequency: 'Monthly',
      Frequencies: ['Monthly', 'daily', 'weekly'],
      page: 'page view',
      pages: ['page view', 'Total form submissions'],
      datepickervalue: [],
      analyze: {
        title: 'Page metrics',
        startdate: '',
        enddate: '',
        metrics: [
          {
            dt: 'PAGE VIEWS',
            dd: 16,
          },
          {
            dt: 'TOTAL FORM SUBMSSIONS',
            dd: 2,
          },
        ],
      },
      style: {
        height: '400px',
        width: '99%',
      },
      barName: ['Direct traffic', 'Referrals', 'Organic social'],
      title: '',
      barX: [
        '3/26/2021',
        '3/27/2021',
        '3/28/2021',
        '3/29/2021',
        '3/30/2021',
        '3/31/2021',
        '4/1/2021',
        '4/2/2021',
        '4/3/2021',
        '4/4/2021',
        '4/5/2021',
        '4/6/2021',
        '4/7/2021',
        '4/8/2021',
        '4/9/2021',
        '4/10/2021',
        '4/11/2021',
        '4/12/2021',
        '4/13/2021',
        '4/14/2021',
        '4/15/2021',
        '4/16/2021',
        '4/17/2021',
        '4/18/2021',
        '4/19/2021',
        '4/20/2021',
        '4/21/2021',
        '4/22/2021',
        '4/23/2021',
        '4/24/2021',
      ],
      info: [
        {
          name: 'Direct traffic',
          type: 'line',
          itemStyle: {
            normal: { lineStyle: { color: '#E5851A' }, color: '#E5851A' },
          },
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            10,
            10,
            10,
            10,
            10,
            30,
            30,
            30,
            30,
            30,
            30,
            30,
            32,
            34,
            36,
            38,
            40,
            40,
            40,
            40,
            40,
          ],
        },
        {
          name: 'Referrals',
          type: 'line',
          itemStyle: {
            normal: { lineStyle: { color: '#57BD06' }, color: '#57BD06' },
          },
          data: [],
        },
        {
          name: 'Organic social',
          type: 'line',
          itemStyle: {
            normal: { lineStyle: { color: '#1AD2E5' }, color: '#1AD2E5' },
          },
          data: [],
        },
      ],
      //表格
      showLock: true,
      multipleSelection: ['Direct traffic', 'Referrals', 'Organic social'], //多选选中数据
      tableAttr: [
        { column: 'SOURCE', key: 'name', lock: false, sort: true },
        { column: 'PAGE VIEWS', key: 'pageviev', lock: false, sort: true },
        {
          column: 'TOTAL FORM SUBMSSIONS',
          key: 'totalform',
          lock: false,
          sort: true,
        },
      ],
      tableData: [
        {
          color: '#E5851A',
          name: 'Direct traffic',
          pageviev: '9',
          totalform: '5',
        },
        {
          color: '#57BD06',
          name: 'Referrals',
          pageviev: '3',
          totalform: '3',
        },
        {
          color: '#1AD2E5',
          name: 'Organic social',
          pageviev: '6',
          totalform: '1',
        },
      ],
    }
  },
  watch: {
    multipleSelection() {
      for (let j = 0; j < this.multipleSelection.length; j++) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].name == this.multipleSelection[j]) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[i],
                true
              )
            })
          }
        }
      }
    },
  },
  methods: {
    handleCommand(command) {
      this.Daterange = command
    },
    handledate(date) {
      this.Frequency = date
    },
    handlepage(page) {
      this.page = page
    },
    legendselect(obj) {
      let checkboxarr = []
      for (let key in obj) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].name == key && obj[key] == true) {
            checkboxarr.push(key)
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[i],
                true
              )
            })
          }
          if (this.tableData[i].name == key && obj[key] == false) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[i],
                false
              )
            })
          }
        }
      }
      this.multipleSelection = checkboxarr
    },
    handleSelectionChange(val) {
      this.multipleSelection = []
      for (let i = 0; i < val.length; i++) {
        this.multipleSelection.push(val[i].name)
      }
    },
    titimg(tit) {
      tit.lock = true
      tit.sort = !tit.sort
      //dd为false  向上
      //dd为true   向下
    },
  },
  created() {
    let ndate = new Date()
    this.datepickervalue = [ndate, ndate]
    for (let i = 0; i < this.tableData.length; i++) {
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
}
.Performance {
  width: 100%;
}
.perdate {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
}
.Daterange {
  float: left;
  margin-left: 16px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  min-width: 200px;
  .el-dropdown-link {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
  }
}
.datepicker {
  margin-left: 15px;
  ::v-deep {
    .el-range-editor.el-input__inner {
      padding: 0;
      cursor: pointer;
      width: 220px;
    }
    .el-input__inner {
      border: 0;
      height: 20px;
      line-height: 20px;
    }
    .el-range-input {
      cursor: pointer;
      color: #006dcc;
    }
    .el-date-editor .el-range__icon {
      line-height: 22px;
      margin: 0;
      width: 20px;
    }
    .el-date-editor .el-range-separator {
      line-height: 20px;
      padding: 0;
    }
    .el-date-editor .el-range-input {
      width: 86px;
    }
    .el-date-editor .el-range__close-icon {
      line-height: 22px;
      width: 20px;
    }
  }
}
.pageview {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  margin-top: 10px;
  .pagechart {
    width: 100%;
    height: 63px;
  }
  ::v-deep .el-dropdown {
    margin-top: 23px;
    margin-left: 2%;
  }
  .el-dropdown-link {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
  }
}
.source {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 20px;
  .sourcenav {
    font-family: MicrosoftYaHei-Bold;
    letter-spacing: 0;
    h6 {
      font-size: 16px;
      color: #333333;
      font-weight: bolder;
      margin-top: 23px;
      margin-left: 10px;
    }
    p {
      font-size: 12px;
      color: #333333;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}
</style>
